<template>
  <section id="calendar_day">
    <header>
      <h1>{{ $t('calendar.title') }}</h1>

      <div>
        <v-btn fab small :disabled="isStart" @click="previousDay">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <div>{{ beforeDay }}</div>
        <div>{{ presentDay }}</div>
        <v-btn fab small :disabled="isToday" @click="nextDay">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </header>

    <div id="calendar__container">
      <canvas-day-calendar :today="today" :step-time="5" read-only/>
    </div>

    <footer>
      <v-progress-circular indeterminate color="primary" v-if="loading"/>
      <day-information-container v-if="!loading && loaded" read-only/>
    </footer>
  </section>
</template>

<script>
import CanvasDayCalendar from '@/components/Calendar/Calendar'
import { mapActions, mapGetters } from 'vuex'
import DayInformationContainer from '@/components/Calendar/DayInformationContainer'
import { dateDifference, dateEqual } from '@/store/helpers/survey/date'

export default {
  name: 'DayCalendar',

  components: {
    DayInformationContainer,
    CanvasDayCalendar,
  },

  props: {
    day: {
      required: false,
      type: String,
      default: null,
    },
  },

  data: () => ({
    today: new Date(),
    loaded: false,
    loading: false,
  }),

  computed: {
    ...mapGetters([
      'surveyResults',
      'userConnected',
    ]),
    beforeDay () {
      if (this.today) {
        return this.capitalize(
            (new Date(this.today.getTime() - 86400000)).toLocaleDateString(
                navigator.language || 'fr-FR',
                {
                  weekday: 'long',
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric',
                },
            ),
        )
      }

      return ''
    },
    isToday () {
      return dateEqual(this.today, new Date())
    },
    isStart () {
      if (this.userConnected && this.userConnected.inclusionDate && this.today) {
        return dateEqual(this.today, this.userConnected.inclusionDate)
      }

      return true
    },
    presentDay () {
      if (this.today) {
        return this.capitalize(
            this.today.toLocaleDateString(
                navigator.language || 'fr-FR',
                {
                  weekday: 'long',
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric',
                },
            ),
        )
      }

      return ''
    },
  },

  created () {
    this.loaded = false
    this.loading = true
    this.loadMedicines()

    this.loadResults()
  },

  watch: {
    userConnected: {
      deep: true,
      handler: function () {
        this.loadResults()
      },
    },
  },

  methods: {
    ...mapActions([
      'addCapsule',
      'addSlider',
      'loadCapsules',
      'loadMedicines',
      'loadSliders',
      'loadSurveyResults',
      'resetAgenda',
    ]),
    initValues () {
      const results = this.surveyResults.INS_QUOTIDIEN

      if (results) {
        const resultDate = dateDifference(this.today, this.userConnected.inclusionDate)
        const todayResults = results[resultDate]
        if (todayResults) {
          const answers = results[resultDate].answers
          let meds = []
          let nap = []
          let lumino = []
          let sleep = []

          if (answers.GRAPH) {
            meds = answers.GRAPH.meds
            nap = answers.GRAPH.nap
            lumino = answers.GRAPH.lumino
            sleep = answers.GRAPH.sleep
          }

          this.loadCapsules({ capsules: meds, today: this.today })
          this.loadSliders({ nap, lumino, sleep, today: this.today })
        }
      }
      this.loading = false
      this.loaded = true
    },
    loadResults () {
      this.resetAgenda()
      if (this.userConnected.id) {
        this.today = new Date(this.userConnected.inclusionDate)
        if (this.day) {
          this.today.setDate(this.today.getDate() + parseInt(this.day))
        }

        this.loadSurveyResults(this.userConnected.id)
            .then(this.initValues)
      }
    },
    nextDay () {
      this.updateDay(1)
    },
    previousDay () {
      this.updateDay(-1)
    },
    updateDay (diff) {
      this.loading = true
      this.loaded = false
      const newDate = new Date(this.today)
      newDate.setDate(newDate.getDate() + diff)
      this.today = newDate

      this.resetAgenda()
      this.initValues()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

#calendar_day {
  > header {
    > div {
      padding: 0 25px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      @media all and (min-width: $s) {
        padding: 0 50px;
      }

      > div {
        font-size: 18px;
        text-align: center;

        @media all and (min-width: $s) {
          font-size: 40px;
        }
      }
    }
  }

  #calendar__container {
    display: flex;

    > div {
      display: flex;
      flex-direction: column;

      button {
        margin-bottom: 20px;
      }
    }
  }

  footer {
    display: flex;
    justify-content: center;

    #day-information__container {
      width: 100%;
    }
  }
}
</style>
